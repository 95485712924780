import { Grid } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { ContactRequestDTO } from "dto/static/contactrequest.dto";
import React, { useState } from "react";
import { ContactRequestService } from "services/static/contactrequest.service";
import { FormContactRequest } from "./FormContactRequest";
import { useMessage } from "providers/MessageProvider";
import { useLabel } from "hooks/useLabel";
import { MessageTypes } from "tools/types/messagetypes";
import { ContactHome } from "../page/block/ContactHome";

const service = new ContactRequestService();
const ContactRequestHome: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultObject, setDefaultObject] = useState<ContactRequestDTO>(
    new ContactRequestDTO()
  );
  const { registerMessage } = useMessage();
  const { LL } = useLabel();
  const onSubmit = (obj: ContactRequestDTO) => {
    if (!obj) return;
    setLoading(true);
    service.add(handleResult, {}, obj);
  };
  const handleResult = (result: ResultObjectDTO<ContactRequestDTO>) => {
    if (!result) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setDefaultObject(new ContactRequestDTO());
    registerMessage(
      LL("you_message_was_sent"),
      MessageTypes.MESSAGE_SUCCESS,
      "200"
    );
  };
  return (

    <Grid container className="paddingGeneral container contactPage">
      <Grid item xs={12} sm={6}><ContactHome /></Grid>
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={5}>
      <FormContactRequest
        defaultObject={defaultObject}
        onSubmit={onSubmit}
        loading={loading}
      />
      </Grid>
    </Grid>

  );
};

export { ContactRequestHome };
