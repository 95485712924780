import { Box } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { PageDto } from "dto/static/page.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: PageDto;
};

const PageContent: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  return (
    <Box className="contact-home" sx={{ fontSize: { xs: "18px", md: "22px" }}}>
      <HtmlTag
        content={CommonTools.processObjectField(object, ["_description"])}
      />
    </Box>
  );
};

export { PageContent };
