type CCType = (identifier: string, value?: string) => string;

export class Config {
  public static _CC: boolean | CCType = false;
  public static setCC(value: boolean | CCType) {
    Config._CC = value;
  }
  public static CC(identifier: string, value?: string): string {
    if (Config._CC === false) return value || "";
    return (Config._CC as CCType)(identifier, value);
  }
  public static API_URL = process.env.REACT_APP_API_SERVER || "";
  public static ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER || "";
  public static WWW_SERVER_URL = process.env.REACT_APP_WWW_SERVER || "";
  public static ON_PAGE = Number(process.env.REACT_APP_ONPAGE) || 25;
  public static CLOSE_MESSAGE_SUCCESS =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_SUCCESS) || 10000;
  public static CLOSE_MESSAGE_WARNING =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_WARNING) || 7000;
  public static CLOSE_MESSAGE_ERROR =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_ERROR) || 5000;
  public static DRAWER_WIDTH = 300;
  public static GOOGLE_VERIFICATION =
    process.env.REACT_APP_GOOGLEVERIFICATION || "";
  public static YANDEX_VERIFICATION =
    process.env.REACT_APP_YANDEXVERIFICATION || "";
  public static PUBLIC_URL = process.env.REACT_APP_PUBLICURL || "";
  public static TIME_BUFFER_JWT =
    Number(process.env.REACT_APP_TIME_BUFFER_JWT) || 600;
  public static URL_GENERATE_TOKEN = "/jwt/generate";
  public static PREFIX_APP = process.env.REACT_APP_PREFIX_APP || "site";
  public static DEFAULT_LANGUAGE =
    process.env.REACT_APP_DEFAULT_LANGUAGE_CODE || "en";
  public static BOT_DECLARATION =
    /bot|googlebot|crawler|spider|robot|crawling/i;
  public static API_IP = process.env.REACT_APP_API_IP || "http://ip-api.com";
  public static ID_COPYRIGHT_PAGE =
    process.env.REACT_APP_ID_COPYRIGHT_PAGE || "";
  public static ID_ABOUT_US_ON_HOME_PAGE_BLOCK =
    process.env.REACT_APP_ID_ABOUT_US_ON_HOME_PAGE_BLOCK || "";
  public static ID_ABOUT_US_PAGE = process.env.REACT_APP_ID_ABOUT_US || "";
  public static ID_COOKIE_POLICY_PAGE =
    process.env.REACT_APP_ID_COOKIE_POLICY || "";
  public static DRAWER_WIDTH_MAIN = 500;
  public static DRAWER_ANCHOR: "left" | "top" | "right" | "bottom" | undefined =
    "left";
  public static DEFAULT_TAB = "generalinfo";
  public static HEADER_HEIGHT = 110;
  public static FOOTER_HEIGHT = 168;
  public static FOOTER_HEIGHT_MOBILE = 389;
  public static ID_GALLERY_HOME_PAGE =
    process.env.REACT_APP_ID_GALLERY_HOME_PAGE || "";

  public static ID_CONTACTS_PAGE = process.env.REACT_APP_ID_CONTACTS || "";

  public static API_KEY_GOOGLE_MAP =
    process.env.REACT_APP_API_KEY_GOOGLE_MAP || "";

  public static TAG_MANAGER_ARGS = {
    gtmId: process.env.REACT_APP_TAG_MANAGER_ID || "",
  };

  public static GA_ID = process.env.REACT_APP_GA_ID || "";
}
