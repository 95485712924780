import { Box, Typography } from "@mui/material";
import { ServiceDto } from "dto/static/service.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: ServiceDto;
};

const ServiceShortDescription: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  return (

        <Box>
          <Typography>
            {CommonTools.processObjectField(object, ["_shortdescription"])}
          </Typography>
        </Box>

  );
};

export { ServiceShortDescription };
