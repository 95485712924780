import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { GalleryList } from "components/elements/gallery/GalleryList";
import { PortfolioDTO } from "dto/static/portfolio.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: PortfolioDTO;
  open: boolean;
  handleClose: () => void;
};

const PortfolioDetail: React.FC<Props> = ({ object, open, handleClose }) => {
  if (!object) return null;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth className="dialogAdd">
      <DialogTitle className="title">
        {CommonTools.processObjectField(object, ["_name"])}
      </DialogTitle>
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{pt:0}}>
        <Box>
          <Typography color={"#000"}>
            {CommonTools.processObjectField(object, ["_shortdescription"])}
          </Typography>
        </Box>
        {CommonTools.processObjectField(object, ["url"]) ? (
          <Box>
            <Link
              href={CommonTools.processObjectField(object, ["url"])}
              target="_blank"
              rel="noreferrer"
            >
              {CommonTools.processObjectField(object, ["url"])}
            </Link>
          </Box>
        ) : null}

        <Box>
          <GalleryList objects={object._galleryActiveList} excludeDefault reverse/>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export { PortfolioDetail };
